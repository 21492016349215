const config = {
    emailSubject: 'TODO: change this',
    emailBodyTemplate: (state, zipCode) => `TODO: change this`,
    emails: [
        "Mark_Warner@warner.senate.gov",
        "Tim_Kaine@kaine.senate.gov",
        "carolyn.king@mail.house.gov",
        "jennifer.kiggans@mail.house.gov",
        "elaine.luria@mail.house.gov",
        "bobby.scott@mail.house.gov",
        "donald.mceachin@mail.house.gov",
        "abigail.spanberger@mail.house.gov",
        "donald.beyer@mail.house.gov",
        "h.griffith@mail.house.gov",
        "jennifer.wexton@mail.house.gov",
        "gerald.connolly@mail.house.gov"
    ],
    emailBody: `Dear Representative,

I hope this message finds you in good health. I am writing to bring to your attention a critical humanitarian situation in Bani Suheila, Gaza, that requires immediate and urgent assistance. I am deeply concerned about the plight of a particular family there, and while I am not related to them, their dire circumstances compel me to seek your intervention.


Background:
This matter involves a family in Bani Suheila, Gaza, including a retired school principal coping with paraplegia and Parkinson's disease, and a total of 10 individuals, some of whom are seniors in their 80s and 90s. They are currently trapped in their residence due to imminent conflict, lacking essential resources and facing severe medical challenges.


Current Situation:
The family is in a critical state, with no access to food, water, and necessary medical supplies. Their medical conditions, including diabetes mellitus, hypertension, advanced kidney disease, and Parkinson’s disease, make their situation particularly precarious. They are unable to evacuate due to mobility issues and ongoing military threats in the area.


Attempts at Getting Them to Safety:
Efforts have been made to reach out to organizations such as the Red Cross and the Palestinian Red Crescent, as well as filing assistance forms with the Department of State. However, these efforts have not yielded the necessary support, leaving the family in a vulnerable position.


Request for Action:
I urge you to consider the following actions to assist this family:
1) Facilitate an emergency evacuation for the family members to a safer location, such as the city of Rafah, Gaza, or the Rafah border with Egypt.
2) Ensure their protection and safe passage through conflict zones.
3) Coordinate with relevant authorities in Gaza and neighboring countries to expedite the evacuation process.


This situation is of utmost urgency, and your intervention could significantly impact the survival and safety of this family. I am ready to provide any further information required and deeply appreciate your attention to this urgent humanitarian crisis.


Thank you for your consideration and prompt action.


Further information:
Their current address:
Khaled Bin Al-Walid Street, Qahwaji neighborhood
Bani Suheila, Gaza
More notes on their location:
Bassam Qawaji residence near Al-Nahhas Supermarket
Town of Bani Suheila is located east of Khan Yunis City, Gaza
Local contact numbers:
+972 59-926-0720
+972 56-726-0720
+972 59-948-7445
+972 59-755-4871
 

Current residents needing help:
Fatma Shahin Mother 01.01.1944 Paraplegic and has advanced Parkinson’s disease
Hadeel Shahin Sister 02.03.1969 Motor function disability due to brain tumor removal
Wael Qudeih Brother-in-law 06.10.1966
Sara Qudeih Neice 01.30.2001
Sami Qudeih Nephew 01.01.2007 Hip defect, requires disability assistance, chronic pain, lack of mobility
Alma Qudeih Niece 09.16.2013
Rama Qudeih Niece 08.23.1999
Muath Abudaqqa Niece’s husband 03.02.2000
Mohammed Qudeih Sister’s father in law 1927 Diabetes mellites, on multiple diabetic
Medications. Severe bladder dysfunction
Horiya Alqarra Qudeih Sister’s mother-in-law 1948 Degenerative joint disease with severe back pain and weakness. Advanced kidney disease, requiring multiple medications
`,
    getRepresentativesApiUrl: 'https://qpxwe9576i.execute-api.us-east-1.amazonaws.com/dev/get-representatives' // TODO: use new API that takes address
};

export default config;