
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar'; // Import the NavBar component
import HomePage from './components/HomePage'; // Your home page component
import ResponsePage from './components/ResponsePage';
import { fetchCardsFromApi } from './api';
import EmailRedirect from './components/EmailRedirect';
import config from './config';


function App() {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    fetchCardsFromApi()
      .then(cards => {
        setCards(cards);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      });
  }, []);

  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage cards={cards} loading={loading} error={error} />} />
        <Route path="/response/:cardId/:choice" element={<ResponsePage cards={cards} loading={loading} error={error} />} />
        <Route path="/evacuate_bani_suheila" element={<EmailRedirect
          bcc={config.emails}
          subject='Urgent Request for Evacuation Assistance in Bani Suheila, Gaza'
          body={config.emailBody}
        />} />
        {/* Other routes would go here */}
      </Routes>
    </Router>
  );
}

export default App;
