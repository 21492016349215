import React from 'react';
import {
    Box,
    VStack,
    Heading,
    Text,
    Button,
    HStack,
    Badge
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

function CardList({ cards }) {
    const navigate = useNavigate();

    const handleButtonClick = (cardId, choice) => {
        navigate(`/response/${cardId}/${choice}`);
    };

    return (
        <VStack spacing={4} align="stretch" mt={4} mb={4}> {/* align="stretch" will make all children width equal */}
            {cards.map(card => (
                <Box
                    key={card.id}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    color='white'
                    bg='#682E63'
                    p={4}
                    w="100%" // This ensures each card stretches to the full width of its container
                    maxW="xl" // This sets a maximum width for each card
                >
                    {localStorage.getItem(card.id) && (
                        <Badge mb="2" colorScheme={localStorage.getItem(card.id) === 'agree' ? 'green' : 'red'}
                        >
                            {localStorage.getItem(card.id).toUpperCase()}
                        </Badge>
                    )}
                    <Heading size="md" mb={2}>{card.name}</Heading>
                    <Text fontSize="sm" color="gray.500" mb={2}>{card.category}</Text>
                    <Text mb={4}>{card.description}</Text>
                    <Text fontSize="sm" color="gray.500" mb={4}>{card.bill}</Text>
                    <HStack justify="end" spacing={3}> {/* justify="end" will align buttons to the end */}
                        <Button onClick={() => handleButtonClick(card.id, 'agree')}>
                            Agree
                        </Button>
                        <Button onClick={() => handleButtonClick(card.id, 'disagree')}>
                            Disagree
                        </Button>
                    </HStack>
                </Box>
            ))}
        </VStack>
    );
}

export default CardList;
