// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

const EmailRedirect = ({ /*emails, bcc, subject, body, redirectMessage*/ }) => {
    // const navigate = useNavigate();

    // useEffect(() => {
    //     let mailtoLink = `mailto:${emails ? emails.join(',') : ''}`;
    //     if (bcc && bcc.length > 0) {
    //         mailtoLink += `?bcc=${bcc.join(',')}`;
    //     }
    //     mailtoLink += `&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    //     window.location.href = mailtoLink;

    //     setTimeout(() => {
    //         navigate(-1); // navigate back after 5 seconds
    //     }, 5000);
    // }, [emails, bcc, subject, body, navigate]);

    return (
        <div>The family has requested to pause emails for now. Please check again later.</div>
        // <div>{redirectMessage || `Redirecting to email template for ${subject}...`}</div>
    );
};

export default EmailRedirect;