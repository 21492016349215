export function fetchCardsFromApi() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(
                [
                    {
                        "category": "Crime",
                        "bill": "H.R.316 — 118th Congress (2023-2024)",
                        "name": "Improving the Federal Response to Organized Retail Crime Act of 2023",
                        "description": "This bill mandates federal agencies to develop a strategy for better coordination with state and local law enforcement to combat organized retail crime. It requires agencies like the Department of Justice and Homeland Security to enhance information sharing, aid in evidence compilation, and increase cooperation with the retail industry and crime task forces. Additionally, the Government Accountability Office is tasked with reporting on public-private coordination in deterring and investigating such crimes.",
                        "id": "6f34380c-0ae6-4aba-8a2d-7e7362b909bd"
                    },
                    {
                        "category": "LGBTQ",
                        "bill": "S.Res.282 — 118th Congress (2023-2024)",
                        "name": "A resolution recognizing June 2023 as \"LGBTQ Pride Month\".",
                        "description": "This resolution expresses support for the rights, freedoms, and equal treatment of lesbian, gay, bisexual, transgender, and queer (LGBTQ) individuals. The resolution recognizes that LGBTQ rights are human rights and protected by the laws of the United States and international treaties. Additionally, the resolution encourages the celebration of LGBTQ Pride Month in June.",
                        "id": "166ad53a-4db9-4a10-a221-2c1e705acef5"
                    },
                    {
                        "category": "LGBTQ",
                        "bill": "S.2246 — 118th Congress (2023-2024)",
                        "name": "Gender-Affirming CARE Act",
                        "description": "This bill seeks to authorize the appropriation of funds to the National Institutes of Health and the Centers for Disease Control and Prevention. The purpose is to support research on barriers to gender-affirming care and the impact of these barriers on health outcomes. It proposes a funding of $25,000,000 annually for each agency from 2024 through 2029, specifically for this research, in addition to any other authorized appropriations.",
                        "id": "e84af5f9-c3c6-4e7a-8c0a-da3b8c8686c5"
                    },
                    {
                        "category": "Abortion",
                        "bill": "H.R.5806 — 118th Congress (2023-2024)",
                        "name": "Ending Chemical Abortions Act of 2023",
                        "description": "This bill proposes to amend U.S. law to prohibit chemical abortions. It aims to criminalize prescribing, dispensing, or selling any drug or chemical for abortion, with penalties including imprisonment and fines. The bill includes exceptions for contraception before conception, treatment of miscarriages, and cases where a woman's life is endangered by the pregnancy. It also specifies that women who undergo chemical abortions cannot be criminally prosecuted.",
                        "id": "b7a06cf2-38ec-4cee-a64c-821a42949ce4"
                    },
                    {
                        "category": "Housing and Homelessness",
                        "bill": "H.R.307 — 118th Congress (2023-2024)",
                        "name": "Public Housing Emergency Response Act",
                        "description": "This bill authorizes additional assistance from the Public Housing Capital Fund to public housing agencies (PHAs) based upon capital needs as determined by the PHA's most recent physical needs assessment. The physical needs assessment identifies work that a PHA would need to undertake to bring its units up to certain energy conservation standards and other standards.",
                        "id": "900aa48c-0365-4dd9-8888-c1dfd4aef623"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.R.6113 — 118th Congress (2023-2024)",
                        "name": "Israel Supplemental Appropriations Act of 2023",
                        "description": "This bill provides extra funding in 2024 for the U.S. to assist Israel, mainly through the Department of Defense and the Department of State. It includes money for Israel's defense systems and reimburses the U.S. for defense services offered to Israel. The bill also says this money cannot be used for the war in Ukraine or for groups in Gaza linked to terrorism.",
                        "id": "9ed05e4a-2f24-4ffb-80ce-092c0e16ba28"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.Res.786 — 118th Congress (2023-2024)",
                        "name": "Calling for an immediate deescalation and cease-fire in Israel and occupied Palestine.",
                        "description": "This resolution urges the President to immediately call for and to facilitate de-escalation and a cease-fire in Israel and Palestinian Territories. The resolution also calls on the President to promptly send and facilitate the entry of humanitarian assistance into Gaza.",
                        "id": "45148552-7c5f-4ebc-9eaa-9ce64d9bd58e"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.R.3103 — 118th Congress (2023-2024)",
                        "name": "Defending the Human Rights of Palestinian Children and Families Living Under Israeli Military Occupation Act",
                        "description": "This bill aims to promote and protect the human rights of Palestinians living under Israeli military occupation. It focuses on issues like the detention of Palestinian children, the seizure and destruction of Palestinian property, and the annexation of Palestinian land, all in violation of international law. The bill also includes measures to limit U.S. assistance to Israel, particularly in activities violating human rights or international law.",
                        "id": "b6461558-5d41-45e6-a9a4-6b4576ddec2c"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.J.Res.102 — 118th Congress (2023-2024)",
                        "name": "Providing for congressional disapproval of the issuance of a license to export with respect to the proposed export of defense articles, including technical data, and defense services to the Government of Israel.",
                        "description": "This resolution seeks congressional disapproval for issuing a license to export defense articles, technical data, and services to the Government of Israel. Specifically, it aims to prohibit the transfer of Spice Family Gliding Bomb Assemblies to Israel's Ministry of Defense, as described in a certification sent to Congress. The resolution was referred to the Committee on Foreign Affairs.",
                        "id": "1431d07b-e805-4e92-b7e3-a58ab60f2b1a"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.Res.388 — 118th Congress (2023-2024)",
                        "name": "Recognizing the ongoing Nakba and Palestine refugees rights.",
                        "description": "This resolution serves to recognize the ongoing Nakba (catastrophe) and Palestine refugees' rights. It also states that it is the policy of the United States to denounce threats and condemn certain actions by Israel against the Palestinian people.",
                        "id": "5fa1cef7-6cc2-42de-8167-6b6eb3fbb739"
                    },
                    {
                        "category": "Funding to Israel and Palestinian Humanitarian Crisis",
                        "bill": "H.R.3477 — 118th Congress (2023-2024)",
                        "name": "To require a report on the death of Shireen Abu Akleh.",
                        "description": "This resolution would require the Director of the FBI, Secretary of State, Director of National Intelligence, and Secretary of Defense to provide a report (within 30 days of being passed) to Congress details regarding the death of Palestinian-American Shireen Abu Akleh, an American journalist who was shot in the head by Israeli forces.",
                        "id": "f752f817-3f2e-4b99-837d-3f83e49943d7"
                    },
                    {
                        "category": "Insider Trading (Reps)",
                        "bill": "S.693 — 118th Congress (2023-2024)",
                        "name": "Eliminating Executive Branch Insider Trading Act",
                        "description": "This bill bans certain high-ranking executive branch officials and their spouses from owning or trading specific investments like individual stocks, except for diversified funds or U.S. Treasury securities. It allows assets in qualified blind trusts but requires profits from prohibited transactions to be forfeited to the Treasury and may result in a civil fine. The Department of Justice can sue violators, and the Government Accountability Office must regularly audit and report on compliance with these rules.",
                        "id": "1a6d3b57-d0fc-4b2d-bdd5-6ea1aa796dd9"
                    },
                    {
                        "category": "Lobbying and Funding",
                        "bill": "S.829 — 118th Congress (2023-2024)",
                        "name": "Disclosing Foreign Influence in Lobbying Act",
                        "description": "This bill requires registered lobbyists to disclose any foreign countries or political parties that are involved in the direction, planning, supervision, or control of the lobbyist's activities.",
                        "id": "84db6bbc-d1c6-48a1-9101-edf69cf091ab"
                    },
                    {
                        "category": "Budget Deficit",
                        "bill": "H.R.438 — 118th Congress (2023-2024)",
                        "name": "True Cost Act",
                        "description": "This bill mandates the consideration of climate change factors in cleanup actions at Superfund sites, focusing on the threat to health and the environment from local natural disasters and extreme weather. It requires a five-year review of these sites to ensure ongoing protection of human health and the environment against such hazards. The review must assess if the chosen cleanup methods remain effective in the context of climate change-related disasters and extreme weather events.",
                        "id": "976a0ab2-a3cc-4785-a862-55a2d92d5dfb"
                    },
                    {
                        "category": "Climate Change",
                        "bill": "H.R.1444 — 118th Congress (2023-2024)",
                        "name": "Preparing Superfund for Climate Change Act of 2023",
                        "description": "The bill requires that climate change impacts, like local natural disasters and extreme weather, are considered when cleaning up hazardous Superfund sites. It acknowledges that some cleanup methods leave hazardous substances on site, necessitating a five-year review for safety. During these reviews, the effectiveness of cleanup methods must be reassessed in light of increasing climate-related risks.",
                        "id": "838c29ad-89ff-43e0-b783-cb5446ac351f"
                    },
                    {
                        "category": "Gun Violence",
                        "bill": "S.1893 — 118th Congress (2023-2024)",
                        "name": "Office of Gun Violence Prevention Act of 2023",
                        "description": "The bill aims to set up a Federal Advisory Council to support victims of gun violence, providing them with resources and assistance. It includes representatives from various government departments and includes victims and professionals who assist victims of gun violence. The council's primary goal is to develop and share best practices and resources to aid victims, and it is set to operate for five years.",
                        "id": "b00ae418-9e23-48cd-8ffe-fa743b4abf20"
                    },
                    {
                        "category": "Inflation",
                        "bill": "H.R.252 — 118th Congress (2023-2024)",
                        "name": "Inflation Prevention Act of 2023",
                        "description": "The \"Inflation Prevention Act of 2023\" aims to restrict congressional spending that could increase inflation unless the current inflation rate is below 4.5%. It sets a point of order in both the Senate and the House of Representatives against considering spending bills that might raise inflation when it's above this threshold. The bill also requires the Congressional Budget Office to estimate the inflationary impact of proposed spending and notify Congress if inflation exceeds 4.5%.",
                        "id": "c0999937-537d-45e4-a31c-4c34f6c08d44"
                    },
                    {
                        "category": "Healthcare",
                        "bill": "H.R.2907 — 118th Congress (2023-2024)",
                        "name": "Let Doctors Provide Reproductive Health Care Act",
                        "description": "This bill protects and supports health care providers who offer lawful reproductive health care services, including abortion and contraception, by prohibiting interference with their work. It allows legal action against those who violate these protections and bars states from using federal funds to pursue legal actions against such providers. Additionally, the bill provides grants to assist providers facing legal challenges or security issues related to their reproductive health care services.",
                        "id": "258a5089-ac42-4709-a028-527d6a8a51a9"
                    },
                    {
                        "category": "Data Privacy",
                        "bill": "H.R.1165 — 118th Congress (2023-2024)",
                        "name": "Data Privacy Act of 2023",
                        "description": "This bill increases privacy protections for personal data at financial institutions, covering everyone, not just existing customers. It requires these institutions to clearly explain why they collect data and allows people to opt out and delete their data. The bill also prevents states from having different privacy rules, ensuring uniform standards across the country.",
                        "id": "d26cdb78-9e6f-4bd1-9de5-edec23982d27"
                    }
                ]);
        }, 1000);
    });
}

