import React, { useEffect } from 'react';
import { Container, Heading, Text, Badge, Flex } from '@chakra-ui/layout';
import { useParams } from 'react-router-dom';
import Form from './Form';
import { Spinner } from '@chakra-ui/react';

function ResponsePage({ cards, loading }) {
    const { cardId, choice } = useParams();

    // Scroll to the top of the page when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!cards.length || loading) return <Spinner />;
    const bill = cards.find(({ id }) => id === cardId);

    return (
        <div>
            <Badge ml="5" colorScheme={choice === 'agree' ? 'green' : 'red'}>{choice === 'agree' ? 'Agree' : 'Disagree'}</Badge>
            <Container mt="4" mb="4" maxW="container.xl" centerContent>
                <Flex align="center" justify="flex-start" w="full">
                    <Heading as="h1" size="xl" textAlign="left" mb={6} ml={2}>
                        {bill.name}
                    </Heading>
                </Flex>
                <Text mb={6}>{bill.description}</Text>
                <Form bill={bill} choice={choice} />
            </Container>
        </div>
    );
}

export default ResponsePage;