import React, { useState } from 'react';
import {
    Select,
    Input,
    FormControl,
    FormLabel,
    Button,
    Spinner,
    Box,
    Heading,
    Text,
    useColorModeValue
} from '@chakra-ui/react';
import reps from '../reps.json'; // Assuming reps.json is in the src directory
import config from '../config';

const generateEmailTemplate = (bill, choice, firstName, lastName, address, city, state) => {
    const agreeTemplate = `Dear Representative,

I am writing to express my support for ${bill.name} (${bill.bill}).

As a concerned constituent, I believe that this bill will have a positive impact on our community. The provisions outlined in the bill align with my views and I believe it will bring about necessary change.

I urge you to vote in favor of this bill. Your support will be greatly appreciated by myself and many others in our community.

Thank you for your time and consideration.

Sincerely,
${firstName} ${lastName}
${address} ${city}, ${state}
`;

    const disagreeTemplate = `Dear Representative,

I am writing to express my concerns about ${bill.name} (${bill.bill}).

As a concerned constituent, I believe that this bill could have negative implications for our community. The provisions outlined in the bill do not align with my views and I am worried about the potential consequences.

I urge you to reconsider your stance on this bill. Your understanding and consideration of my concerns will be greatly appreciated.

Thank you for your time and consideration.

Sincerely,
${firstName} ${lastName}
${address} ${city}, ${state}
`;

    return choice === 'agree' ? agreeTemplate : disagreeTemplate;
};

const Form = ({ bill, choice }) => {
    // Extract state names from the JSON object keys
    const US_STATES = Object.keys(reps);
    const [selectedState, setSelectedState] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const [showEmailInfo, setShowEmailInfo] = useState(false); // New state for controlling the display of email info
    const [emailInfo, setEmailInfo] = useState({ emails: [], body: '' });
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    const [copyEmailsText, setCopyEmailsText] = useState('Copy Emails');
    const [copyMessageText, setCopyMessageText] = useState('Copy Message');
    const [copySubjectText, setCopySubjectText] = useState('Copy Subject');

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');

    const backgroundColor = useColorModeValue('lightBackgroundColor', 'darkBackgroundColor');
    const textColor = useColorModeValue('lightTextColor', 'darkTextColor');

    const emailSubject = choice === 'agree' ? `I Support ${bill.name}` : `I Oppose ${bill.name}`;

    // Handle state selection change
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };

    // Handle state selection change
    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    // Function for copying emails to clipboard
    const copyEmailsToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(emailInfo.emails.join(', '));
            setCopyEmailsText('Emails Copied!');
            setTimeout(() => setCopyEmailsText('Copy Emails'), 2000); // Reset text after 2 seconds
        } catch (err) {
            console.error('Failed to copy emails:', err);
        }
    };

    const copySubjectToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(emailSubject);
            setCopySubjectText('Subject Copied!');
            setTimeout(() => setCopySubjectText('Copy Subject'), 2000); // Reset text after 2 seconds
        } catch (err) {
            console.error('Failed to copy subject:', err);
        }
    };

    // Function for copying message to clipboard
    const copyMessageToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(emailInfo.body);
            setCopyMessageText('Message Copied!');
            setTimeout(() => setCopyMessageText('Copy Message'), 2000); // Reset text after 2 seconds
        } catch (err) {
            console.error('Failed to copy message:', err);
        }
    };

    const toggleEmailInfoDisplay = () => {
        setShowEmailInfo(!showEmailInfo);
    };

    // Form submission handler
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true); // Set loading to true when the API call starts

        const formData = new FormData(event.target);
        const state = formData.get('state');
        const zipCode = formData.get('zipcode');
        const address = formData.get('address');
        const city = formData.get('city');
        const firstName = formData.get('firstName');
        const lastName = formData.get('lastName');

        // Construct the query parameters
        const queryParams = new URLSearchParams({
            zipcode: zipCode,
            address: address,
            city: city,
            state: state,
            firstName: firstName,
            lastName: lastName,
            billId: bill.id,
            choice
        }).toString();

        // Get senators for the selected state from your local JSON file
        const localSenators = reps[state]?.senators || [];

        // Fetch additional representatives based on the zip code
        try {
            const response = await fetch(`${config.getRepresentativesApiUrl}?${queryParams}`);
            if (response.ok) {
                const additionalReps = await response.json();

                // Combine local senators' emails with the ones from the API response
                const allEmails = localSenators.map((senator) => senator.email).concat(additionalReps.map((rep) => rep.email));

                const emailBody = generateEmailTemplate(bill, choice, firstName, lastName, address, city, state);
                // Generate the mailto link with all emails
                const mailtoLink = generateMailtoLink(allEmails, emailBody);

                setEmailInfo({ emails: allEmails, body: emailBody });

                // Save user's choice to localStorage
                localStorage.setItem(bill.id, choice);

                // Redirect to the mailto link
                window.location.href = mailtoLink;

                setTimeout(() => {
                    setShowHelpMessage(true);
                }, 5000);
            } else {
                throw new Error('API call failed');
            }
        } catch (error) {
            console.error('Failed to fetch representatives:', error);
        } finally {
            setLoading(false); // Reset loading to false when done or failed
        }
    };

    // Generate mailto link function now accepts an array of emails
    const generateMailtoLink = (emails, body) => {
        return `mailto:${emails.join(',')}?subject=${emailSubject}&body=${encodeURIComponent(body)}`;
    };

    return (
        <>
            <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
                <FormControl id="firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input name="firstName" placeholder="Enter your First Name" value={firstName} onChange={handleFirstNameChange} />
                </FormControl>

                <FormControl id="lastName" mt={4} isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input name="lastName" placeholder="Enter your Last Name" value={lastName} onChange={handleLastNameChange} />
                </FormControl>

                <FormControl id="address" mt={4} isRequired>
                    <FormLabel>Address</FormLabel>
                    <Input name="address" placeholder="Enter your Address" value={address} onChange={handleAddressChange} />
                </FormControl>

                <FormControl id="city" mt={4} isRequired>
                    <FormLabel>City</FormLabel>
                    <Input name="city" placeholder="Enter your City" value={city} onChange={handleCityChange} />
                </FormControl>

                <FormControl id="state" mt={4} isRequired>
                    <FormLabel>State</FormLabel>
                    <Select name="state" placeholder="Select state" onChange={handleStateChange} value={selectedState}>
                        {US_STATES.map((state) => (
                            <option value={state} key={state}>
                                {state}
                            </option>
                        ))}
                    </Select>
                </FormControl>

                <FormControl id="zipcode" mt={4} isRequired>
                    <FormLabel>Zipcode</FormLabel>
                    <Input name="zipcode" placeholder="Enter your Zipcode" />
                </FormControl>
                <Button mt={4} color="#FFA07A" type="submit" isLoading={loading}>
                    {loading ? <Spinner size="sm" /> : 'Submit'}
                </Button>
            </form>

            {showHelpMessage && (
                <div style={{ marginTop: "10px", textAlign: "center" }}>
                    <button
                        onClick={toggleEmailInfoDisplay}
                        style={{ background: "none", color: "#FFA07A", textDecoration: "underline", border: "none", padding: "0", cursor: "pointer", fontSize: "1.3em" }}>
                        Not working? Click here to copy the email content
                    </button>
                </div>
            )}

            {/* Email info display and copy button */}
            {showEmailInfo && (
                <Box mt={4} p={4} border="1px" borderColor="gray.500" borderRadius="md" bg={backgroundColor} boxSize="border-box" w="100%" maxW="600px" mx="auto">
                    <Heading mb={4} color={textColor} fontSize="lg" textAlign="center">Having Trouble with Your Email Client?</Heading>
                    <Text mb={4} color="gray.500" fontSize="md" textAlign="center">No worries! You can manually copy the content below.</Text>

                    <div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ color: "#ddd", fontSize: "1.2em" }}>Email Addresses:</span>
                        <button onClick={copyEmailsToClipboard} style={{ background: "none", color: "#007bff", border: "none", cursor: "pointer" }}>
                            {copyEmailsText}
                        </button>
                    </div>
                    <p style={{ marginBottom: "20px", color: "#bbb", fontSize: "1em", wordBreak: "break-all" }}>{emailInfo.emails.join(', ')}</p>

                    <div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ color: "#ddd", fontSize: "1.2em" }}>Subject:</span>
                        <button onClick={copySubjectToClipboard} style={{ background: "none", color: "#007bff", border: "none", cursor: "pointer" }}>
                            {copySubjectText}
                        </button>
                    </div>
                    <p style={{ marginBottom: "20px", color: "#bbb", fontSize: "1em" }}>{emailSubject}</p>

                    <div style={{ marginBottom: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ color: "#ddd", fontSize: "1.2em" }}>Message:</span>
                        <button onClick={copyMessageToClipboard} style={{ background: "none", color: "#007bff", border: "none", cursor: "pointer" }}>
                            {copyMessageText}
                        </button>
                    </div>
                    <div style={{ marginBottom: "20px", color: "#bbb", fontSize: "1em", border: "1px solid #555", padding: "15px", borderRadius: "4px", backgroundColor: "#0b0d12", maxHeight: "300px", overflowY: "auto" }}>
                        {emailInfo.body}
                    </div>
                </Box>
            )}

        </>
    );
};

export default Form;

