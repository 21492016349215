import React from 'react';
import { Container } from '@chakra-ui/react';
import CardList from './CardList';

function NavBar({ cards, loading, error }) {
    return (
        <Container margin={0} maxW="100%" centerContent bg="gray.200">
            <CardList cards={cards} />
        </Container>
    );
}

export default NavBar;
