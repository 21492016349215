import React from 'react';
import { Flex, Link, Image } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

function NavBar() {
    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            paddingLeft="1rem"
            bg="#050505" // Changed to a darker shade suitable for dark mode
            color="white"
            m={0}
        >
            <Flex align="center" mr={5} p={0}>
                <Link as={RouterLink} to="/" _hover={{ textDecoration: 'none' }}>
                    <Image src="/Navbar Logo Purple.svg" alt="Your Logo" boxSize="100px" />
                </Link>
            </Flex>

            {/* Here you can add more navigation items */}
        </Flex>
    );
}

export default NavBar;
