// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import App from './App';

// const theme = extendTheme({
//   config: {
//     // Set initial color mode to dark
//     initialColorMode: 'dark',
//     // If you want to prevent users from toggling to light mode, use the `useSystemColorMode: false` setting
//     useSystemColorMode: false,
//   },
//   styles: {
//     global: {
//       body: {
//         backgroundColor: '#071207', // Change this to your preferred color
//         color: 'white',
//       },
//     },
//   },
// });

const theme = extendTheme({
  colors: {
    brand: {
      50: '#F2E9F3', // Lightest Purple
      100: '#DCC2D9',
      200: '#C69BBF',
      300: '#B074A5',
      400: '#9A4D8B',
      500: '#843671',
      600: '#682E63', // Your provided color
      700: '#4C1655',
      800: '#300047',
      900: '#140039', // Darkest Purple
    },
  },
    styles: {
      global: {
        body: {
          backgroundColor: '#682E63', // Change this to your preferred color
          color: 'white',
        },
      },
    }
});

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
